import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import * as queries from "../../../operations/queries";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import PrintIcon from "@mui/icons-material/Print";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
    },
  },
});

function Order() {
  const { t } = useTranslation();
  const classes = useStyles();
  const loggedInToken = localStorage.getItem("loggedInCust");
  let subTotal: any;
  let grandTotal: any;
  const { orderNumber } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dense, setDense] = React.useState(false);
  const {
    data: orderDetailsRes,
  } = useQuery(queries.customerQueries.CUSTOMER_ORDER_DETAILS_QUERY, {
    variables: { orderNumber },
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
      },
    },
  });
  if (!orderDetailsRes || !orderDetailsRes.customer || !orderDetailsRes.customer.orders || !orderDetailsRes.customer.orders.items) {
    return <div>Loading...</div>;
  }
  const orderDetails = orderDetailsRes?.customer?.orders?.items[0];


  if (!orderDetails || !orderDetails.order_date) {
    return <div>Loading...</div>;
  }

  const extractDate = (dateTimeString: string) => {
    const datePart = dateTimeString.split(' ')[0]; // Extracting the date part
    return datePart;
  };

  orderDetails?.items.map((data: any, index: any) => {
    return subTotal =
      data?.product_sale_price?.currency +
      "" +
      data?.product_sale_price?.value * data?.quantity_ordered;
  });
  grandTotal =
    orderDetails?.total?.grand_total?.currency +
    " " +
    orderDetails?.total?.grand_total?.value;
  const data = [
    { primaryText: "Subtotal", primaryValue: "AED 10.00" },
    { primaryText: "Cash on Delivery Fee", primaryValue: "AED 10.00" },
    { primaryText: "Shipping & Handling", primaryValue: "AED 10.00" },
    { primaryText: "Grand Total", primaryValue: grandTotal },
  ];
  //   orderDetails?.items.map((data:any)=>{
  //     rows.push(
  //         createData(data?.product_name, data?.product_sku, data?.product_sale_price?.value,data?.quantity_ordered, 'fddfg'),
  //       )

  //   })
  //   const rows = [
  //     createData('Segafredo - Nespresso Compatible & Compostable Capsules -Lungo', '1643882785', 'AED 10.00', 1, 'AED 10.00'),
  //     createData('Segafredo - Nespresso Compatible & Compostable Capsules -Lungo', '1643882785', 'AED 10.00', 1, 'AED 10.00')
  // ];

  // Output: United States
  return (
    <div className="order-wrapper">
      <Container maxWidth={false}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Card
                sx={{
                  minWidth: "100%",
                  boxShadow: "none",
                  background: "#F0F2F2",
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {orderDetails?.number}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {orderDetails?.status}
                      </Typography>
                      <Typography variant="h6" component="div" sx={{ fontSize: 14 }}>
                        {/* {orderDetails?.order_date} */}
                        {extractDate(orderDetails.order_date)}
                      </Typography>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        disableElevation
                        sx={{
                          my: 2,
                          textTransform: "capitalize",
                          color: "#fff",
                          fontSize: "18px",
                          fontWeight: "500",
                          padding: "6px 34px",
                        }}
                      >
                        {orderDetails?.status}
                      </Button>
                    </div>
                  </Box>
                </CardContent>
              </Card>
              <Card sx={{ minWidth: "100%", boxShadow: "none" }}>
                <CardContent sx={{ bgcolor: "#F9F9F9" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {t("buyer_dashboard.orders.item_order")}
                      </Typography>
                    </div>
                    <div>
                      <Button size="large" startIcon={<PrintIcon />}>
                        {t("buyer_dashboard.orders.print_btn")}
                      </Button>
                    </div>
                  </Box>
                  <Box>
                    <TableContainer className="order-tbl-container">
                      <Table
                        className={classes.table}
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead sx={{ height: 60 }}>
                          <TableRow>
                            <TableCell>
                              {t("buyer_dashboard.orders.prod_name")}
                            </TableCell>
                            <TableCell align="right">
                              {t("buyer_dashboard.orders.sku")}
                            </TableCell>
                            <TableCell align="right">
                              {t("buyer_dashboard.orders.price")}
                            </TableCell>
                            <TableCell align="right">
                              {t("buyer_dashboard.orders.qty")}
                            </TableCell>
                            <TableCell align="right">
                              {t("buyer_dashboard.orders.subtotal")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody sx={{ height: 100 }}>
                          {orderDetails?.items.map((row: any, index: any) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row?.product_name}
                              </TableCell>
                              <TableCell align="right">
                                {row?.product_sku}
                              </TableCell>
                              <TableCell align="right">
                                {row?.product_sale_price?.currency}&nbsp;
                                {row?.product_sale_price?.value}
                              </TableCell>
                              <TableCell align="right">
                                {row?.quantity_ordered}
                              </TableCell>
                              <TableCell align="right">{subTotal}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6}>
              <Card
                sx={{
                  minWidth: "100%",
                  boxShadow: "none",
                  background: "#F0F2F2",
                  border: "1px solid #D6D4D4",
                }}
              >
                <CardContent>
                  <List dense={dense}>
                    {data.map((item, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={item.primaryText} />
                        <ListItemText primary={item.primaryValue} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  bgcolor: "#F7F6F6",
                  mb: 3,
                  border: "1px solid #D2CDCD",
                  p: 1,
                }}
              >
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ fontSize: "18px", fontWeight: "500" }}
                >
                  {t("buyer_dashboard.orders.order_info")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  bgcolor: "#F7F6F6",
                  mb: 3,
                  border: "1px solid #D2CDCD",
                  p: 1,
                }}
              >
                <TableContainer className="order-tbl-container">
                  <Table
                    className={classes.table}
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead sx={{ height: 60 }}>
                      <TableRow>
                        <TableCell>
                          {t("buyer_dashboard.orders.ship_addr")}
                        </TableCell>
                        <TableCell align="right">
                          {t("buyer_dashboard.orders.ship_method")}
                        </TableCell>
                        <TableCell align="right">
                          {t("buyer_dashboard.orders.bill_addr")}
                        </TableCell>
                        <TableCell align="right">
                          {t("buyer_dashboard.orders.payment_method")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ height: 100 }}>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            sx={{ fontSize: 16, fontWeight: 400 }}
                          >
                            {orderDetails?.shipping_address?.firstname}&nbsp;
                            {orderDetails?.shipping_address?.lastname}
                          </Typography>
                          <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            sx={{ fontSize: 16, fontWeight: 400 }}
                          >
                            {orderDetails?.shipping_address?.street[0]}
                          </Typography>
                          <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            sx={{ fontSize: 16, fontWeight: 400 }}
                          >
                            {orderDetails?.shipping_address?.city},
                          </Typography>
                          <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            sx={{ fontSize: 16, fontWeight: 400 }}
                          >
                            {orderDetails?.shipping_address?.country_code}
                          </Typography>
                          <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            sx={{ fontSize: 16, fontWeight: 400 }}
                          >
                            Mob&nbsp;:&nbsp;
                            {orderDetails?.shipping_address?.telephone}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {orderDetails?.shipping_address?.shipping_method}
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            sx={{ fontSize: 16, fontWeight: 400 }}
                          >
                            {orderDetails?.billing_address?.firstname}&nbsp;
                            {orderDetails?.billing_address?.lastname}
                          </Typography>
                          <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            sx={{ fontSize: 16, fontWeight: 400 }}
                          >
                            {orderDetails?.billing_address?.street[0]}
                          </Typography>
                          <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            sx={{ fontSize: 16, fontWeight: 400 }}
                          >
                            {orderDetails?.billing_address?.city},
                          </Typography>
                          <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            sx={{ fontSize: 16, fontWeight: 400 }}
                          >
                            {orderDetails?.shipping_address?.country_code}
                          </Typography>
                          <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            sx={{ fontSize: 16, fontWeight: 400 }}
                          >
                            Mob&nbsp;:&nbsp;
                            {orderDetails?.billing_address?.telephone}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {orderDetails?.payment_methods[0]?.name}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
export default Order;
