import React, { useState, useContext, useEffect, useMemo, } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Container } from "@material-ui/core";
import * as mutations from "../../../operations/mutations";
import CardHeader from "@mui/material/CardHeader";
import { FormControl, Input, InputLabel } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import MenuItem from "@mui/material/MenuItem";
import SvgIcon from "@mui/material/SvgIcon";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import CardMedia from "@mui/material/CardMedia";
import { useLocation, useSearchParams } from "react-router-dom";
import * as queries from "../../../operations/queries";
import { useQuery } from "@apollo/client";
import { CountryContext, mainContext } from "../MainComponent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";
import AddNewAddress from "./AddNewAddress";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CCAvenueForm from "../../../components/ccevenueForm/CcevenueForm";
import Modal from "../../../components/Modal/Modal";
import { toast } from "react-toastify";
import PaymentFailer from "../../../components/PaymentFal/PaymentFal/PaymentFalier";
import PreHeader from "../Layout/PreLayout/PreHeader";
import PostpayWidget from "../Innerpages/postpayStrip/postPayStrip";
import TabbyWidget from "../Innerpages/postpayStrip/tabbyStrip";
import theme from "../../../theme";
import { useMediaQuery } from "@mui/material";
import { useMutation } from "@apollo/client";
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';

function CustomSelectIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.2825 7.28294C13.3528 7.21322 13.4086 7.13027 13.4467 7.03888C13.4848 6.94748 13.5044 6.84945 13.5044 6.75044C13.5044 6.65143 13.4848 6.5534 13.4467 6.46201C13.4086 6.37062 13.3528 6.28766 13.2825 6.21794L9.53255 2.46794C9.46282 2.39765 9.37987 2.34185 9.28848 2.30377C9.19708 2.2657 9.09905 2.24609 9.00005 2.24609C8.90104 2.24609 8.80301 2.2657 8.71161 2.30377C8.62022 2.34185 8.53727 2.39765 8.46755 2.46794L4.71755 6.21794C4.64725 6.28766 4.59145 6.37062 4.55338 6.46201C4.5153 6.5534 4.4957 6.65143 4.4957 6.75044C4.4957 6.84945 4.5153 6.94748 4.55338 7.03887C4.59145 7.13027 4.64725 7.21322 4.71755 7.28294C4.78727 7.35324 4.87022 7.40903 4.96161 7.44711C5.05301 7.48519 5.15104 7.50479 5.25005 7.50479C5.34905 7.50479 5.44708 7.48519 5.53848 7.44711C5.62987 7.40903 5.71282 7.35324 5.78254 7.28294L9.00005 4.05794L12.2175 7.28294C12.2873 7.35324 12.3702 7.40903 12.4616 7.44711C12.553 7.48519 12.651 7.50479 12.75 7.50479C12.8491 7.50479 12.9471 7.48519 13.0385 7.44711C13.1299 7.40903 13.2128 7.35324 13.2825 7.28294ZM9.53255 15.5329L13.2825 11.7829C13.3525 11.713 13.4079 11.63 13.4458 11.5386C13.4836 11.4473 13.5031 11.3493 13.5031 11.2504C13.5031 11.0507 13.4238 10.8592 13.2825 10.7179C13.2126 10.648 13.1296 10.5925 13.0382 10.5547C12.9469 10.5169 12.8489 10.4974 12.75 10.4974C12.5503 10.4974 12.3588 10.5767 12.2175 10.7179L9.00005 13.9429L5.78254 10.7179C5.71282 10.6476 5.62987 10.5919 5.53848 10.5538C5.44708 10.5157 5.34905 10.4961 5.25005 10.4961C5.15104 10.4961 5.05301 10.5157 4.96161 10.5538C4.87022 10.5919 4.78727 10.6476 4.71755 10.7179C4.64725 10.7877 4.59145 10.8706 4.55338 10.962C4.5153 11.0534 4.4957 11.1514 4.4957 11.2504C4.4957 11.3495 4.5153 11.4475 4.55338 11.5389C4.59145 11.6303 4.64725 11.7132 4.71755 11.7829L8.46755 15.5329C8.53727 15.6032 8.62022 15.659 8.71161 15.6971C8.80301 15.7352 8.90104 15.7548 9.00005 15.7548C9.09905 15.7548 9.19708 15.7352 9.28848 15.6971C9.37987 15.659 9.46282 15.6032 9.53255 15.5329Z"
        fill="#000"
        fillOpacity="0.51"
      />
    </SvgIcon>
  );
}

declare const window: any;

function Checkout(props: any) {

  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [searchParams] = useSearchParams()
  const params = searchParams.get("paymentFail")
  const { store } = useContext(CountryContext);
  const storePath = window.location.pathname;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [custCountry, setCustCountry] = useState(String());
  // const [custState, setCustState] = useState(String());
  const [resError, setResError] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [paymentvalue, setPaymentValue] = useState("");
  const [isEdit, setIsEdit] = useState("");
  let loggedInToken = localStorage.getItem("loggedInCust");
  const [billingOnLoad, setBillingOnLoad] = useState(true);
  const [showPwd, setShowPwd] = useState(false);

  const [open, setOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [successUrl, setSuccessUrl] = useState("");
  const [shippingToggle, setShipingToggle] = useState(false);
  const [checkShipingToggle, setCheckShipingToggle] = useState(false);
  const [procedToPayToggle, setProcedToPayToggle] = useState(false);
  const [shippingSuccess, setShippingSuccess] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedRegion, setSelectedRegion] = useState({
    bill_region: "",
    region_id: 0,
  });
  const [telerToken, setTelerToken] = useState("");
  const [ccevenueRes, setCcevenueRes] = useState("");
  const [isOpenRegister, setIsOpenRegister] = useState(false);
  const [isOpenFPwd, setIsOpenFPwd] = useState(false);
  const [telrRedirectUrl, setTelrRediretUrl] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [billFname, setBillFname] = useState("");
  const [billSname, setBillSname] = useState("");
  const [billAddr1, setBillAddr1] = useState("");
  const [billAddr2, setBillAddr2] = useState("");
  const [billCity, setBillCity] = useState("");
  const [billZip, setBillZip] = useState("00000");
  const [billEmail, setBillEmail] = useState("");
  const [billTel, setBillTel] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [shipAmt, setShipAmt] = useState<any>();
  const [shipAmtCurr, setShipAmtCurr] = useState("");
  const [orderSummaryData, setOrderSummaryData] = useState<any>();
  const [cartSubTotal, setCartSubTotal] = useState<any>();
  const [cartSubTotalCurr, setCartSubTotalCurr] = useState<any>();
  const [prodQty, setProQty] = useState<any>();
  const [isQtyDisabled, setIsQtyDisabled] = useState(false);
  const [vat, setVat] = useState<any>();
  const [customDuty, setCustomDuty] = useState<any>();
  const selectedStores =
    store && store !== "" ? store : storePath?.split("/")?.[1];
  const [selectedStore, setSelectedStore] = useState<any>(selectedStores);
  const [cartUpdated, setCartUpdated] = useState<any>()
  const [paymentFailed, setPaymentFailed] = useState(false)
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [cartItemUid, setCartItemUid] = useState("")
  const [couponCode, setCouponCode] = useState('');
  const [couponCodeSuccess, setCouponCodeSuccess] = useState(false)
  const [applyCouponToCart] = useMutation(mutations.couponMutation.APPLY_COUPON_MUTATION);
  const [removeCouponFromCart] = useMutation(mutations.couponMutation.REMOVE_COUPON_MUTATION);
  const [successAddress, setSuccessAddress] = useState(false);
  const [islogin, setIslogin] = useState<any>(loggedInToken && JSON.parse(loggedInToken))
  const [placeOrderLoading, setPlaceOrderLoading] = useState<boolean>(false);
  /* const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const iframeRef = useRef<HTMLIFrameElement>(null); */

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (params === "true") {
      setPaymentFailed(true)
    }
  }, [params])

  /* const Values = {
    telr_token: telerToken,
    store_id: "20906",
    auth_key: "jFBzR-ZJzq~524k5",
    amount: "100",
    currency: "AED",
    bill_fname: "",
    bill_sname: "",
    bill_addr1: "",
    bill_addr2: "",
    bill_city: "",
    bill_region: "",
    region_id: "",
    bill_zip: "00000",
    bill_country: selectedCountry,
    bill_email: "",
    bill_tel: "",
    pin_code: "",
  }; */



  useEffect(() => {
    if (store?.length > 0) {
      localStorage.setItem("store_code", store);
    }
    onCountryChange();
  }, [store]);
  console.log("successAddress", successAddress);

  // const customHeader = {
  //   store: store && store !== "" ? store : storePath?.split("/")?.[1],
  //   Connection: "keep-alive",
  // };

  // const { data: logoRes } = useQuery(queries.headerQueries.GET_LOGO, {
  //   context: {
  //     headers: customHeader,
  //   },
  // });

  // const [addProductsToCart] = useMutation(
  //   mutations.addToCartMutation.ADD_SIMPLE_CART_MUTATION,
  //   {
  //     context: {
  //       headers: {
  //         Authorization: loggedInToken
  //           ? `Bearer ${JSON.parse(loggedInToken)}`
  //           : "",
  //         Connection: "keep-alive",
  //         store: store,
  //       },
  //     },
  //   }
  // );
  const [updateCartItems] = useMutation(
    mutations.addToCartMutation.UPDATE_CART_ITEMS,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          Store: store
        },
      },
    }
  );

  // const logoData = logoRes?.storeConfig;
  // const logoUrl =
  //   logoData?.secure_base_media_url + "logo/" + logoData?.header_logo_src;
  const onCountryChange = async () => {
    const store_code = await localStorage.getItem("store_code");
    if (store_code === "ae_en" || store_code === "ae_ar") {
      setSelectedCountry("AE");
    } else if (store_code === "sa_en" || store_code === "sa_ar") {
      setSelectedCountry("SA");
      localStorage.setItem("getCountryCode", "SA");
    } else if (store_code === "en" || store_code === "ar") {
      setSelectedCountry("OM");
    } else if (store_code === "in") {
      setSelectedCountry("IN");
    }
  };
  const store_code = localStorage.getItem("store_code");
  const handleOpenModal = (isEdit: any) => {
    if (isEdit) {
      setIsEdit(isEdit);
    } else {
      setIsEdit("");
    }
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
    setIsEdit("");
  };
  const { quest_cart_id, setCartCount, set_quest_cart_id } =
    useContext(mainContext);
  let subtotal: any = "";
  let getQty: any = "";

  let subTotalAmount: number = 0;

  const shippingMethods = [
    {
      carrier_code: "",
      method_code: "",
    },
  ];

  // const handledCheckQty = (event: SelectChangeEvent) => {
  //   setCheckQty(event.target.value as string);
  // };
  const { data: cartDetailsRes, refetch: refetchCartData } = useQuery(
    queries.cartQueries.GET_CART_DETAILS,
    {
      variables: { cart_id: quest_cart_id },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );
  const cartDetails = cartDetailsRes?.cart;

  useEffect(() => {
    setOrderSummaryData(cartDetails);
    const qtyData: any = [];
    cartDetails?.items.forEach((data: any) => {
      const datas = {
        id: data?.id,
        sku: data?.product?.sku,
        qty: data?.quantity,
        uid: data?.uid
      };
      getQty = data?.quantity;
      setCartItemUid(data?.uid)
      subtotal = {
        currency: data?.prices?.row_total?.currency,
        amount: data?.prices?.row_total?.value,
      };
      let cartSubTotal = data?.prices?.row_total?.value;
      subTotalAmount += cartSubTotal;
      qtyData.push(datas);
    });
    setVat(cartDetails?.prices?.vat[0]?.amount);
    setCustomDuty(cartDetails?.prices?.custom_duty[0]?.amount);
    setProQty(qtyData);
    setCartSubTotal(subTotalAmount);
    setCartSubTotalCurr(subtotal?.currency);
  }, [cartDetails]);

  let discountPrice: [] = orderSummaryData?.prices?.discounts
  const { data: shippingPayRes, refetch } = useQuery(
    queries.cartQueries.SHIPPING_AND_PAYMENT_METHOD,
    {
      variables: { cart_id: quest_cart_id },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );
  shippingMethods[0].carrier_code =
    shippingPayRes?.cart?.shipping_addresses[0]?.available_shipping_methods[0]?.carrier_code;
  shippingMethods[0].method_code =
    shippingPayRes?.cart?.shipping_addresses[0]?.available_shipping_methods[0]?.method_code;

  useEffect(() => {
    if (shippingToggle === false) {
      if (
        shippingMethods[0]?.carrier_code !== "" &&
        shippingMethods[0]?.carrier_code !== undefined &&
        shippingMethods[0]?.method_code !== undefined &&
        shippingMethods[0].method_code !== ""
      ) {
        handleSetShippingMethod();
        setShippingSuccess(true);
        setShipingToggle(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingMethods, shippingToggle]);

  useEffect(() => {
    // handleSetPaymentMethod(paymentvalue);
    if (shippingSuccess) {
      SetOrderPaymentMethod({
        variables: {
          cartId: quest_cart_id,
          paymentCode: paymentvalue,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentvalue, shippingSuccess]);

  const [setUestEmail] = useMutation(
    mutations.addToCartMutation.SET_GUEST_EMAIL,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const { data: availablePayMethodRes } = useQuery(
    queries.orderQueries.GET_AVAILABLE_PAYMENT_METHODS_QUERY,
    {
      variables: {
        cartId: quest_cart_id,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );
  let custDetailsDataRes: any;
  // if (loggedInToken && loggedInToken !== "" && loggedInToken !== null) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: custDetailsDataRess, refetch: refetchCustInfo } = useQuery(
    queries.customerQueries.GET_CUSTOMER_INFO,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );
  custDetailsDataRes = custDetailsDataRess;
  // }
  useEffect(() => {
    if (successAddress) {
      refetchCustInfo()
    }
    },[successAddress]);

  useEffect(() => {
    if (loggedInToken && loggedInToken !== "" && loggedInToken !== null) {
      refetchCustInfo();
    }
  }, [loggedInToken, refetchCustInfo]);
  let defaultBilling = custDetailsDataRes?.customer?.addresses.filter(function (
    val: any
  ) {
    return val?.default_shipping === true;
  });
  // const handledCustState = (event: SelectChangeEvent) => {
  //   setCustState(event.target.value);
  // };
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  let [generateCustomerToken, { data: generateCustData }] = useMutation(
    mutations.loginMutation.LOGIN_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  useEffect(() => {
    custCartQueryDataRefetch()
      .then((result: any) => {
        if (result?.data?.customerCart?.id) {
          destinationCartId = result?.data?.customerCart?.id
          handleMergeCarts();
          refetchCartData()
        }
      })
      .catch((error) => {
        console.error("login page Refetch error", error);
      });
  }, [islogin])

  const [mergeCartsMutation] = useMutation(mutations.addToCartMutation.MERGE_CARTS, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store && store !== "" ? store : storePath?.split("/")?.[1],
      },
    },
  });
  const handleGenerateToken = async (event: any) => {
    event.preventDefault();
    const data = formData;
    try {
      const response = await generateCustomerToken({
        variables: data,
      });
      generateCustData = response?.data;
      setResError("");
      setShowErrorAlert(false);
      localStorage.setItem(
        "loggedInCust",
        JSON.stringify(generateCustData?.generateCustomerToken?.token)
      );
      setIslogin(generateCustData?.generateCustomerToken?.token)
      navigate(`/${store_code}/checkout`);

    } catch (err: any) {
      if (err) {
        setResError(err?.message);
        setShowErrorAlert(true);
      }
    }
  };

  const { loading: custlload, data: custQueryDataRes, refetch: custCartQueryDataRefetch } = useQuery(
    queries.customerQueries.GET_CUSTOMER_CART_QUERY,
    {
      context: {
        headers: {
          Authorization: islogin
            ? `Bearer ${islogin}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );
  let destinationCartId = custQueryDataRes?.customerCart?.id

  const sessionStoreData = sessionStorage.getItem('storeAndCart');
  const currentsessionStore = sessionStoreData ? JSON.parse(sessionStoreData) : []
  const filteredArray = currentsessionStore && currentsessionStore?.filter((object: any) => object.genStore === selectedStores);

  const handleMergeCarts = async () => {
    try {
      await mergeCartsMutation({
        variables: {
          cartId: quest_cart_id,
          destinationCartId: destinationCartId,
        },
      });
      const sessionStoreData = sessionStorage.getItem('storeAndCart');
      const currentsessionStore = sessionStoreData ? JSON.parse(sessionStoreData) : [];
      const filteredArray = currentsessionStore && currentsessionStore?.filter((object: any) => object.genStore !== selectedStores);
      sessionStorage.setItem("storeAndCart", JSON.stringify(filteredArray))
    } catch (error) {
      console.error('login page Merge Carts Error:', error);
    }
  };

  const { data: countryRegRes } = useQuery(
    queries.configurationQueries.GET_COUNTRIES,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const selectedCountryData = countryRegRes?.countries?.find(
    (data: any) => data?.two_letter_abbreviation === selectedCountry
  );

  const initialLoad = async () => {
    const region = countryRegRes?.countries?.filter(
      (item: any) => item?.two_letter_abbreviation === selectedCountry
    );
    setCustCountry(selectedCountryData?.two_letter_abbreviation);
    if (region && region.length > 0) {
      await addBillingAddrMutation({
        variables: {
          cartId: quest_cart_id,
          firstname: "-",
          lastname: "-",
          company: "jebelz",
          street: "-",
          city: "-",
          region: selectedCountryData?.available_regions[0]?.code,
          regionId: selectedCountryData?.available_regions[0]?.id,
          postCode: store_code === "in" ? "-" : "00000",
          country_code: selectedCountryData?.two_letter_abbreviation,
          telephone: "-",
          save_in_address_book: true,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });
      await addShipingAddrMutation({
        variables: {
          cartId: quest_cart_id,
          firstname: "-",
          lastname: "-",
          company: "jeblez",
          street: "-",
          city: "-",
          region: selectedCountryData?.available_regions[0]?.code,
          regionId: selectedCountryData?.available_regions[0]?.id,
          postCode: store_code === "in" ? "-" : "00000",
          country_code: selectedCountryData?.two_letter_abbreviation,
          telephone: "-",
          save_in_address_book: true,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });

      refetch();
    }
    if (
      paymentvalue &&
      shippingMethods[0]?.carrier_code !== "" &&
      shippingMethods[0]?.carrier_code !== undefined &&
      shippingMethods[0]?.method_code !== undefined &&
      shippingMethods[0].method_code !== ""
    ) {
      await handleSetPaymentMethod(paymentvalue);
      await handleSetShippingMethod();
    }
  };

  useEffect(() => {
    initialLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custCountry, countryRegRes]);

  const [placeOrderMutation] = useMutation(
    mutations.orderMutation.PLACE_ORDER_MUTATION,
    {
      variables: { cart_id: quest_cart_id },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );
  const [addBillingAddrMutation] = useMutation(
    mutations.orderMutation.ADD_BILLING_ADDR,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );
  const [addShipingAddrMutation] = useMutation(
    mutations.orderMutation.ADD_SHIPPING_ADDR,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const [setBillingAddrCustMutation] = useMutation(
    mutations.orderMutation.SET_BILLING_ADDRESS_CUST,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const [setShippingAddrCustMutation] = useMutation(
    mutations.orderMutation.SET_SHIPPING_ADDR_CUST,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  useEffect(() => {
    if (billingOnLoad && defaultBilling && defaultBilling.length > 0) {
      setBillingOnLoad(false);
    }
  }, [defaultBilling, billingOnLoad]);

  const [SetOrderPaymentMethod] = useMutation(
    mutations.orderMutation.SET_ORDER_Add_PAYMENT_METHOD_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const handleSetPaymentMethod = async (value: any) => {
    const cartId = quest_cart_id;

    try {
      await SetOrderPaymentMethod({
        variables: {
          cartId: cartId,
          paymentCode: paymentvalue,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });
    } catch (error) {
      console.error("Error setting payment method:", error);
    }
  };

  const paymentMethodRes = useMemo(() => {
    if (availablePayMethodRes?.cart?.available_payment_methods) {
      
      if (selectedStores === 'ae_en') {
        setPaymentValue(availablePayMethodRes?.cart?.available_payment_methods[0]?.code);
      } else {
        setPaymentValue(
          availablePayMethodRes?.cart?.available_payment_methods[0]?.code
          );
        }
        return availablePayMethodRes?.cart?.available_payment_methods;
      }
    }, [availablePayMethodRes, selectedStores]);
    
  const cartId = quest_cart_id;

  const [setShippingMethod] = useMutation(
    mutations.orderMutation.SET_SHIPPING_METHOD_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const handleSetShippingMethod = async () => {
    try {
      await setShippingMethod({
        variables: {
          cartId: cartId,
          shippingMethods: shippingMethods,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      }).then((res) => {
        setShipAmt(
          res?.data?.setShippingMethodsOnCart?.cart?.shipping_addresses[0]
            ?.selected_shipping_method?.amount?.value
        );
        setShipAmtCurr(
          res?.data?.setShippingMethodsOnCart?.cart?.shipping_addresses[0]
            ?.selected_shipping_method?.amount?.currency
        );
      });
    } catch (error) {
      console.error("Error setting shipping method:", error);
    }
  };
  const handlePaymentChange = async (event: any) => {
    
    if (event.target.value !== undefined || event.target.value !== null) {
      setPaymentValue(event.target.value);
    }
    if (event.target.value === "telr_telrpayments") {
      // navigate(`/${store}/telrPayment`);
    } else {
      await handleSetPaymentMethod(event.target.value);
      // handleSetShippingMethod();
    }
    refetchCartData();
  };

  const handleSetShippingAdd = async (addId: any) => {
    await setBillingAddrCustMutation({
      variables: {
        cart_id: quest_cart_id,
        customer_address_id: addId,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    });
    await setShippingAddrCustMutation({
      variables: {
        cart_id: quest_cart_id,
        customer_address_id: addId,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }).then((res) => {
      console.log(res);
      console.log(addId)
      setCheckShipingToggle(true);
    });
  };

  useEffect(() => {
    refetchCartData()
    setIsQtyDisabled(false)
  }, [cartUpdated, refetchCartData])

  //-------- teller -------
  var onTokenReceive = function (telrToken: any) {
    /* Note: Include the received card token from iframe in payment form & send as ivp_ctoken parameter */
    setTelerToken(telrToken);
  };

  useEffect(() => {
    if (paymentvalue === "telr_telrpayments") {
      const script = document.createElement("script");
      script.src = "https://secure.telr.com/jssdk/v2/telr_sdk.js";
      script.async = true;

      script.onload = () => {
        // Initialize Telr SDK here
        const telrSdk = window.telrSdk;
        var telr_params = {
          store_id: 20906,
          currency: "AED",
          test_mode: 1,
          callback: onTokenReceive,
        };
        telrSdk.init(telr_params);
      };
      document.head.appendChild(script);
    }
    else if (paymentvalue === "tabby_installments") {
      const script = document.createElement("script");
      script.src = "https://checkout.tabby.ai/tabby-card.js";
      script.async = true;
      script.onload = () => {
        // Initialize Telr SDK here
        const tabby:any = window.tabby;
        var tabby_params = {
          selector: '#tabbyCard', // empty div for TabbyCard.
          currency: 'AED', // required, currency of your product. AED|SAR|KWD|BHD|QAR only supported, with no spaces or lowercase.
          lang: 'en', // Optional, language of snippet and popups.
          price: 100, // required, total price or the cart. 2 decimals max for AED|SAR|QAR and 3 decimals max for KWD|BHD.
          size: 'narrow', // required, can be also 'wide', depending on the width.
          theme: 'black', // required, can be also 'default'.
          header: false // if a Payment method name present already. 
        }
        //tabby(tabby_params);
      };
      document.head.appendChild(script);
    }
  }, [paymentvalue]);

  const [generatePostPayTokenMutation] = useMutation(
    mutations.postPayMutation.POST_PAY_GENERATE_TOKEN
  );
 
  const [generateTabbyMutation] = useMutation(
    mutations.postPayMutation.TABBY_CHECKOUT
  );

  console.log("--------- paymentvalue ---------", paymentvalue);
  

  const [buildCcavenueCheckoutToken] = useMutation(
    mutations.postPayMutation.CCEVENUE_GENERATE_TOKEN,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const handleQuantityChange = (sku: any, uid: any, newQuantity: any) => {
    const updatedData = prodQty.map((item: any) => {
      if (item.sku === sku) {
        return { ...item, qty: newQuantity };
      }
      return item;
    });
    setProQty(updatedData);

    if (newQuantity !== "") {
      updateCartItems({
        variables: {
          cartId: cartId,
          cartItemUid: uid,
          quantity: newQuantity,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            Store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      })
        .then((res) => {
          console.log('enter Updated cart:', res.data.updateCartItems.cart);
          setCartUpdated(res?.data?.updateCartItems?.cart?.items[0]?.quantity)
          setIsQtyDisabled(true);
        })
        .catch((error) => {
          console.error('enter Error updating cart:', error);
          setIsQtyDisabled(false);
        });
    }
  };

  const SubmitForm = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.preventDefault();
    if (loggedInToken !== null && !checkShipingToggle) {
      toast.error("Please Select Shipping Address!");
      return false;
    } else if (loggedInToken === null && billFname === "") {
      toast.error("First name is required");
      return false;
    } else if (loggedInToken === null && billSname === "") {
      toast.error("Last name is Required");
      return false;
    } else if (loggedInToken === null && billAddr1 === "") {
      toast.error("Street Address is Required");
      return false;
    } else if (loggedInToken === null && billAddr2 === "") {
      toast.error("2 Shipping Address is Required");
      return false;
    } else if (loggedInToken === null && billCity === "") {
      toast.error("Bill City is Required");
      return false;
    } else if (loggedInToken === null && selectedRegion?.bill_region === "") {
      toast.error("Bill Region is Required");
      return false;
    } else if (loggedInToken === null && billEmail === "") {
      toast.error("Bill Email is Required");
      return false;
    } else if (
      loggedInToken === null &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(billEmail)
    ) {
      toast.error("Invalid Email");
      return false;
    } else if (loggedInToken === null && billTel === "") {
      toast.error("Mobile Number Required");
      return false;
    } else if (loggedInToken === null && billZip === "") {
      toast.error("Zip code is Required");
      return false;
    } else if (
      loggedInToken === null &&
      pinCode === "" &&
      paymentvalue === "ccavenue"
    ) {
      toast.error("Pincode is Required");
      return false;
    } else if (
      loggedInToken === null &&
      paymentvalue === "telr_telrpayments" &&
      (!telerToken || telerToken === "")
    ) {
      toast.error("Card details is Required");
      return false;
    }
    const guestVariables = {
      cartId: quest_cart_id,
      firstname: billFname,
      lastname: billSname,
      company: "Jebelz",
      street: billAddr1,
      city: billCity,
      region: selectedRegion?.bill_region,
      regionId: selectedRegion?.region_id,
      postCode: store_code === "in" ? pinCode : "00000",
      country_code: custCountry,
      telephone: billTel,
      save_in_address_book: true,
    };

    let redirectUrl = "";
    let orderNumber = "";
    setProcedToPayToggle(true);
    setPlaceOrderLoading(true); //start loading
    if (loggedInToken === "" || !loggedInToken) {
      await setUestEmail({
        variables: {
          cart_id: quest_cart_id,
          email: billEmail === "-" ? "Jebelz@gmail.com" : billEmail,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });
      addBillingAddrMutation({
        variables: guestVariables,
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });
      await addShipingAddrMutation({
        variables: guestVariables,
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });
    }
    if (
      shippingMethods[0]?.carrier_code !== "" &&
      shippingMethods[0]?.carrier_code !== undefined &&
      shippingMethods[0]?.method_code !== undefined &&
      shippingMethods[0].method_code !== ""
    ) {
      await handleSetShippingMethod();
    }
    await handleSetPaymentMethod(paymentvalue);
    // await handlePlaceOrder();
    await placeOrderMutation({
      variables: {
        cartId: quest_cart_id,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    })
      .then((result) => {
        localStorage.setItem(
          "orderId",
          result.data.placeOrder.order.order_number
        );

        //redirectUrl = `${result.data.placeOrder.order.order_redirect_url}?payment_token=${telerToken}&order_id=${result.data.placeOrder.order.order_number}`;
        redirectUrl = `${result.data.placeOrder.order.order_redirect_url}`;
        orderNumber = result.data.placeOrder.order.order_number;
        setTelrRediretUrl(redirectUrl);
        localStorage.removeItem("quest_cart_id");
        sessionStorage.removeItem("storeAndCart");
        set_quest_cart_id("");
        setCartCount(0);
        // https://jebelz.com/ae_en/cms/empty-cart
      })
      .catch((error) => {
        console.error("Error placing order:", error);
      });

    const newOrderId = localStorage.getItem("orderId");
    if (paymentvalue === "cashondelivery") {
      navigate(`/${store_code}/ordersuccess`);
    } else if (paymentvalue === "telr_telrpayments") {
      window.location.href = `${redirectUrl}?payment_token=${telerToken}&order_id=${orderNumber}`;
    } else if (paymentvalue === "tabby_installments") {
      window.location.href = `${redirectUrl}`;
    } else if (
      paymentvalue === "postpay" &&
      newOrderId !== null &&
      newOrderId !== ""
    ) {
      generatePostPayTokenMutation({
        variables: { orderId: newOrderId },
      })
        .then((res: any) => {
          window.location.href = res?.data?.createPostpayCheckout?.redirect_url;
          localStorage.setItem("orderId", "");
          set_quest_cart_id("");
          setCartCount(0);
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else if (
      paymentvalue === "tabby_installments" &&
      newOrderId !== null &&
      newOrderId !== ""
    ) {
      generateTabbyMutation({
        variables: { orderId: newOrderId },
      })
        .then((res: any) => {
          window.location.href = res?.data?.createPostpayCheckout?.redirect_url;
          localStorage.setItem("orderId", "");
          set_quest_cart_id("");
          setCartCount(0);
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else if (
      paymentvalue === "ccavenue" &&
      newOrderId !== null &&
      newOrderId !== ""
    ) {
      buildCcavenueCheckoutToken({
        variables: { orderId: newOrderId },
      })
        .then((res: any) => {
          setCcevenueRes(res?.data?.buildCcavenueCheckout);
          localStorage.setItem("orderId", "");
          set_quest_cart_id("");
          setCartCount(0);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    setPlaceOrderLoading(false); // Stop loading
  };

  const handleCouponChange = (event: any) => {
    setCouponCode(event.target.value);
  };

  const handleApplyCoupon = async () => {
    try {
      const result = await applyCouponToCart({
        variables: {
          cartId: quest_cart_id,
          couponCode: couponCode,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });
      console.log('Mutation Result:', result);
      setCouponCodeSuccess(true)
      refetchCartData()
    } catch (err: any) {

      console.error('coupon Mutation Error:', err);
      toast.error(`Error applying coupon: ${err.message}`);
      setCouponCodeSuccess(false)
    }
  };
  const handleRemoveCoupon = async () => {
    try {
      const result = await removeCouponFromCart({
        variables: {
          cartId: quest_cart_id,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });

      console.log('Remove Coupon Result:', result);

      toast.success('Coupon removed successfully!');
      setCouponCode('');
      refetchCartData()
      setCouponCodeSuccess(false)
    } catch (error: any) {
      console.error('Remove Coupon Error:', error);

      const errorMessage =
        error.graphQLErrors && error.graphQLErrors.length
          ? error.graphQLErrors[0].message
          : 'An error occurred. Please try again.';

      toast.error(`Error removing coupon: ${errorMessage}`);
      setCouponCodeSuccess(true)
    }
  };

  return (
    <>
      <PreHeader />
      <div className="ckeckout-wrapper">
        <div className="checkout-cont-wrap">
          <Container component="main" maxWidth="xl" style={{marginBottom:"60px"}}>
            {successUrl &&
              successUrl !== "" &&
              paymentvalue === "telr_telrpayments" && (
                <Grid item xs={12}>
                  <iframe
                    src={successUrl}
                    title="success"
                    /* onLoad={()=>setTelrLoading(false)} */
                    // ref={iframeRef}
                    style={{
                      width: " 100%",
                      height: "404px",
                      //  display: isMounted ? "block" : "none",
                    }}
                  />
                </Grid>
              )}

            <Box
              sx={{
                marginTop: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              
              <Grid container spacing={4} className="directionrtl">
                <Grid item xs={12} md={4} lg={4} xl={4}>
                  {loggedInToken !== "" &&
                    loggedInToken !== null &&
                    loggedInToken !== undefined ? (
                    <>
                      {custDetailsDataRes &&
                        custDetailsDataRes?.customer?.addresses.slice(-3).map(
                          // eslint-disable-next-line array-callback-return
                          (data: any, index: any) => {
                            if (index < 3) {
                              return (
                                <Card
                                  key={index}
                                  sx={{
                                    maxWidth: "100%",
                                    boxShadow: "none",
                                    // border: "1px solid #E0DEDE",
                                    border: `${selectedCardIndex === index ? '1px solid #10CDF2' : '1px solid #E0DEDE'}`,
                                    mb: 3,
                                    p: 2,
                                    button: {
                                      filter: selectedCardIndex === index ? 'grayscale(100%)' : 'none',
                                    },
                                  }}
                                >
                                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#eee" }}>
                                    <CardHeader
                                      sx={{
                                        backgroundColor: "#eee",
                                        padding: "12px",
                                        "& .MuiTypography-root": {
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        },
                                      }}
                                      title={`${index + 1}. ${t(
                                        "auth.checkout.ship_addrs"
                                      )}`}
                                    />
                                    {selectedCardIndex === index && (
                                      <DoneIcon sx={{ color: "#10CDF2", marginRight: "10px", fontSize: "50px" }} />
                                    )}
                                    <Button
                                      onClick={() =>
                                        handleOpenModal(data?.id)
                                      }
                                      variant="contained"
                                      disableElevation
                                      sx={{
                                        textTransform: "capitalize",
                                        color: "#fff",
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        padding: "6px 15px"

                                      }}
                                    >
                                      {t("auth.checkout.edit")}
                                    </Button>
                                  </div>

                                  <CardContent
                                    sx={{
                                      px: 0,
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {showErrorAlert && (
                                      <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {resError}
                                      </Alert>
                                    )}
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "flex-start",

                                      }}
                                    >
                                      <div className="address-content">
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{
                                            fontSize: 18,
                                            fontWeight: 400,
                                          }}
                                        >
                                          {data?.firstname}&nbsp;
                                          {data?.lastname}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{
                                            fontSize: 18,
                                            fontWeight: 400,
                                          }}
                                        >
                                          {data?.street[0]}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{
                                            fontSize: 18,
                                            fontWeight: 400,
                                          }}
                                        >
                                          {data?.city},
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{
                                            fontSize: 18,
                                            fontWeight: 400,
                                          }}
                                        >
                                          {data?.country_code}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{
                                            fontSize: 18,
                                            fontWeight: 400,
                                          }}
                                        >
                                          Mob&nbsp;:&nbsp;
                                          {data?.telephone}
                                        </Typography>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                          width: "100%"
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          onClick={() => {
                                            handleSetShippingAdd(data?.id);
                                            setSelectedCardIndex(index);
                                          }
                                          }
                                          disableElevation
                                          sx={{
                                            textTransform: "capitalize",
                                            color: "#fff",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            padding: "6px 15px",
                                            width: "100%",
                                            marginTop: "10px"
                                          }}
                                        >
                                          {t("auth.checkout.ship_here")}
                                        </Button>
                                      </div>
                                    </Box>
                                    {((custDetailsDataRes &&
                                      custDetailsDataRes?.customer?.addresses
                                        .length > 3 &&
                                      index === 2) ||
                                      (custDetailsDataRes &&
                                        custDetailsDataRes?.customer?.addresses
                                          .length < 3 &&
                                        index ===
                                        custDetailsDataRes?.customer
                                          ?.addresses.length -
                                        1)) && (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "end",
                                            justifyContent: "end",
                                            marginTop: "10px"
                                          }}
                                        >
                                          <Button
                                            onClick={() => handleOpenModal("")}
                                            variant="contained"
                                            disableElevation
                                            sx={{
                                              width: "fit-content",
                                              textTransform: "capitalize",
                                              color: "#fff",
                                              fontSize: "18px",
                                              fontWeight: "500",
                                              padding: "6px 15px",
                                            }}
                                          >
                                            {t("auth.checkout.new_addr")}
                                          </Button>
                                        </Box>
                                      )}
                                  </CardContent>
                                </Card>
                              );
                            }
                          }
                        )}
                      {custDetailsDataRes?.customer?.addresses.length === 0 && (
                        <Card>
                          <CardContent>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "end",
                                justifyContent: "space-between",
                              }}
                            >
                              <CardHeader
                                sx={{
                                  backgroundColor: "#eee",
                                  padding: "12px",
                                  width: "100%",
                                  "& .MuiTypography-root": {
                                    fontSize: "16px",
                                    fontWeight: "600",
                                  },
                                }}
                                title={t("auth.checkout.ship_addr_1")}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "end",
                                  justifyContent: "end",
                                }}
                              >
                                <Button
                                  onClick={() => handleOpenModal("")}
                                  variant="contained"
                                  disableElevation
                                  sx={{
                                    width: "fit-content",
                                    textTransform: "capitalize",
                                    color: "#fff",
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    padding: "6px 15px",
                                    marginTop: "10px",
                                  }}
                                >
                                  {t("auth.checkout.new_addr")}
                                </Button>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      )}
                    </>
                  ) : (
                    <>
                      <Card
                        sx={{
                          maxWidth: "100%",
                          boxShadow: "none",
                          border: "1px solid #E0DEDE",
                          mb: 3,
                          p: 2,
                        }}
                      >
                        <CardHeader
                          sx={{
                            backgroundColor: "#eee",
                            padding: "12px",
                            "& .MuiTypography-root": {
                              fontSize: "16px",
                              fontWeight: "600",
                            },
                          }}
                          title={t("auth.checkout.ship_addr_1")}
                        />

                        <CardContent sx={{ px: 0 }}>
                          {showErrorAlert && (
                            <Alert severity="error">
                              <AlertTitle>Login failed</AlertTitle>
                              Use Correct Email & Password
                            </Alert>
                          )}
                          <Box
                            component="form"
                            noValidate
                            sx={{ mt: 3 }}
                            autoComplete="off"
                            className="login-form"
                          >
                            <Grid
                              container
                              spacing={isMobileScreen ? 1 : 2}
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              <Grid item xs={12} className="form-label">
                                <label> {t("auth.checkout.signin")}</label>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <InputLabel htmlFor="input-field">
                                  {t("auth.checkout.email")}
                                </InputLabel>
                              </Grid>
                              <Grid item xs={12} sm={9}>
                                <TextField
                                  autoComplete="off"
                                  name="email"
                                  required
                                  fullWidth
                                  id="email"
                                  onChange={handleChange}
                                  placeholder={t("auth.checkout.email")}
                                  value={
                                    custDetailsDataRes
                                      ? custDetailsDataRes?.customer?.email
                                      : formData.email
                                  }
                                  autoFocus
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <InputLabel htmlFor="input-field">
                                  {t("auth.checkout.password")}
                                </InputLabel>
                              </Grid>

                              <Grid item xs={12} sm={9} position="relative">
                                <TextField
                                  autoComplete="off"
                                  name="password"
                                  type={showPwd ? "text" : "password"}
                                  required
                                  fullWidth
                                  id="password"
                                  value={formData.password}
                                  onChange={handleChange}
                                  placeholder={t("auth.checkout.password")}
                                  autoFocus
                                />
                                <span
                                  className="pwd_visiblity"
                                  onClick={() => setShowPwd(!showPwd)}
                                >
                                  {showPwd ? <Visibility /> : <VisibilityOff />}{" "}
                                </span>
                              </Grid>
                              <Grid item xs={12} sm={3}></Grid>
                              <Grid item xs={12} sm={9}>
                                {/* <Link to="/login"> */}
                                <Button
                                  onClick={handleGenerateToken}
                                  variant="contained"
                                  sx={{
                                    textTransform: "capitalize",
                                    color: "#fff",
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    padding: "6px 34px",
                                  }}
                                >
                                  {t("auth.checkout.login")}
                                </Button>
                                {/* </Link> */}
                              </Grid>

                              <Grid item xs={12} sm={9}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      setIsOpenRegister(true);
                                    }}
                                  >
                                    {t("auth.checkout.registration")}
                                  </div>

                                  <div
                                    onClick={() => setIsOpenFPwd(!isOpenFPwd)}
                                  >
                                    {t("auth.checkout.forgot_password")}
                                  </div>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </CardContent>
                      </Card>
                      <Card
                        sx={{
                          maxWidth: "100%",
                          boxShadow: "none",
                          border: "1px solid #E0DEDE",
                          p: 2,
                        }}
                      >
                        <CardHeader
                          sx={{
                            backgroundColor: "#eee",
                            padding: "12px",
                            "& .MuiTypography-root": {
                              fontSize: "16px",
                              fontWeight: "600",
                            },
                          }}
                          title={t("auth.checkout.guest_checkout")}
                        />
                        <CardContent sx={{ px: 0 }}>
                          <Box component="form" noValidate autoComplete="off" className="guest-register-form">
                            <Grid container spacing={isMobileScreen ? 1 : 2}>
                              <Grid item xs={12}>
                                <InputLabel
                                  htmlFor="input-field"
                                  sx={{ mb: 1 }}
                                  required
                                >
                                  {t("auth.checkout.f_name")}
                                </InputLabel>
                                <TextField
                                  autoComplete="off"
                                  name="bill_fname"
                                  autoFocus={true}
                                  required
                                  fullWidth
                                  id="bill_fname"
                                  placeholder={t("auth.checkout.f_name")}
                                  onChange={(e) => setBillFname(e.target.value)}
                                  value={billFname}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel
                                  htmlFor="input-field"
                                  sx={{ mb: 1 }}
                                  required
                                >
                                  {t("auth.checkout.l_name")}
                                </InputLabel>
                                <TextField
                                  autoComplete="off"
                                  name="bill_sname"
                                  required
                                  fullWidth
                                  id="bill_sname"
                                  placeholder={t("auth.checkout.l_name")}
                                  onChange={(e) => setBillSname(e.target.value)}
                                  value={billSname}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel
                                  htmlFor="input-field"
                                  sx={{ mb: 1 }}
                                  required
                                >
                                  {t("auth.checkout.email_add")}
                                </InputLabel>
                                <TextField
                                  autoComplete="off"
                                  name="bill_email"
                                  required
                                  fullWidth
                                  id="bill_email"
                                  placeholder={t("auth.checkout.email_add")}
                                  onChange={(e) => setBillEmail(e.target.value)}
                                  value={billEmail}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel
                                  htmlFor="input-field"
                                  sx={{ mb: 1 }}
                                  required
                                >
                                  {t("auth.checkout.mob_no")}
                                </InputLabel>
                                <TextField
                                  autoComplete="off"
                                  required
                                  fullWidth
                                  name="bill_tel"
                                  id="bill_tel"
                                  onChange={(e) => setBillTel(e.target.value)}
                                  value={billTel}
                                  placeholder={t("auth.checkout.mob_no")}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel
                                  htmlFor="input-field"
                                  sx={{ mb: 1 }}
                                  required
                                >
                                  {t("auth.checkout.street_add")}
                                </InputLabel>
                                <TextField
                                  autoComplete="off"
                                  required
                                  fullWidth
                                  id="bill_addr1"
                                  name="bill_addr1"
                                  onChange={(e) => setBillAddr1(e.target.value)}
                                  value={billAddr1}
                                  placeholder={t("auth.checkout.street_add")}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel
                                  htmlFor="input-field"
                                  sx={{ mb: 1 }}
                                  required
                                >
                                  {t("auth.checkout.ship_addr_2")}
                                </InputLabel>
                                <TextField
                                  autoComplete="off"
                                  required
                                  fullWidth
                                  id="bill_addr2"
                                  name="bill_addr2"
                                  onChange={(e) => setBillAddr2(e.target.value)}
                                  value={billAddr2}
                                  placeholder={t("auth.checkout.ship_addr_2")}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel
                                  htmlFor="input-field"
                                  sx={{ mb: 1 }}
                                  required
                                >
                                  {t("auth.checkout.city")}
                                </InputLabel>
                                <TextField
                                  autoComplete="off"
                                  required
                                  fullWidth
                                  name="bill_city"
                                  id="bill_city"
                                  onChange={(e) => setBillCity(e.target.value)}
                                  value={billCity}
                                  placeholder={t("auth.checkout.city")}
                                />
                              </Grid>
                              {selectedStores === "in" && (
                                <Grid item xs={12}>
                                  <InputLabel
                                    htmlFor="input-field"
                                    sx={{ mb: 1 }}
                                    required
                                  >
                                    {t("auth.checkout.Pincode")}
                                  </InputLabel>
                                  <TextField
                                    autoComplete="off"
                                    required
                                    fullWidth
                                    name="pin_code"
                                    id="pin_code"
                                    onChange={(e) => setPinCode(e.target.value)}
                                    value={pinCode}
                                    placeholder={t("auth.checkout.Pincode")}
                                  />
                                </Grid>
                              )}

                              {selectedCountryData?.available_regions?.length >
                                0 ? (
                                <Grid item xs={12}>
                                  <InputLabel
                                    htmlFor="input-field"
                                    sx={{ mb: 1 }}
                                    required
                                  >
                                    {t("auth.checkout.region")}
                                  </InputLabel>
                                  <FormControl
                                    fullWidth
                                    sx={{
                                      border: "1px solid #C6C3C3",
                                      borderRadius: "2px",
                                    }}
                                  >
                                    <Select
                                      IconComponent={CustomSelectIcon}
                                      labelId="demo-simple-select-label"
                                      name="bill_region"
                                      id="bill_region"
                                      className="qty-select"
                                      placeholder="Select Region"
                                      onChange={(e: any) => {
                                        setSelectedRegion({
                                          bill_region: e.target.value?.code,
                                          region_id: e.target.value?.id,
                                        });
                                      }}
                                    >
                                      {selectedCountryData?.available_regions?.map(
                                        (region: any) => {
                                          return (
                                            <MenuItem
                                              key={region?.id}
                                              value={region}
                                            >
                                              {region?.name}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              ) : (
                                <Grid item xs={12}>
                                  <InputLabel
                                    htmlFor="input-field"
                                    sx={{ mb: 1 }}
                                    required
                                  >
                                    {t("auth.checkout.region")}
                                  </InputLabel>
                                  <TextField
                                    autoComplete="off"
                                    required
                                    fullWidth
                                    name="bill_region"
                                    id="bill_region"
                                    onChange={(e: any) => {
                                      setSelectedRegion({
                                        bill_region: e.target.value?.code,
                                        region_id: e.target.value?.id,
                                      });
                                    }}
                                    placeholder={t("auth.checkout.region")}
                                  />
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <InputLabel
                                  htmlFor="input-field"
                                  sx={{ mb: 1 }}
                                  id="demo-simple-select-label"
                                >
                                  {t("auth.checkout.country")}
                                </InputLabel>
                                <FormControl
                                  fullWidth
                                  sx={{
                                    border: "1px solid #C6C3C3",
                                    borderRadius: "2px",
                                  }}
                                >
                                  <Select
                                    IconComponent={CustomSelectIcon}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    className="qty-select"
                                    disabled={true}
                                    value={selectedCountry}
                                  >
                                    {countryRegRes?.countries &&
                                      countryRegRes?.countries.map(
                                        (option: any) => {
                                          return (
                                            <MenuItem
                                              key={
                                                option?.two_letter_abbreviation
                                              }
                                              value={
                                                option?.two_letter_abbreviation
                                              }
                                            >
                                              {option?.full_name_english}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Box>
                        </CardContent>
                      </Card>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={4}>
                  <Card
                    sx={{
                      maxWidth: "100%",
                      boxShadow: "none",
                      border: "1px solid #E0DEDE",
                      p: 2,
                    }}
                  >
                    <CardHeader
                      sx={{
                        backgroundColor: "#eee",
                        padding: "12px",
                        "& .MuiTypography-root": {
                          fontSize: "16px",
                          fontWeight: "600",
                        },
                      }}
                      title={t("auth.checkout.payment_method")}
                    />

                    <CardContent sx={{ px: 0 }}>
                      <Box>
                        {!successUrl &&
                          successUrl === "" &&
                          paymentvalue === "telr_telrpayments" &&
                          ccevenueRes === "" && (
                            <FormControl fullWidth>
                              {paymentvalue !== undefined ? (
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  sx={{ gap: "15px" }}
                                  className="payment-method-box"
                                  value={paymentvalue}
                                  onChange={handlePaymentChange}
                                >
                                  {paymentMethodRes &&
                                    paymentMethodRes?.map(
                                      (item: any, index: number) => {
                                        return (
                                          <>
                                            <FormControlLabel
                                              key={index}
                                              value={
                                                paymentMethodRes[index]?.code
                                              }
                                              control={
                                                <Radio className="payment-radio" />
                                              }
                                              label={
                                                paymentMethodRes[index]?.title
                                              }
                                              sx={{
                                                backgroundColor: "#F1F3F6",
                                                mx: 0,
                                                border: "0.25px solid #000",
                                                padding: "10px 20px",
                                                color: "#B0B5B6",
                                              }}
                                            />
                                            {paymentMethodRes[index]?.code ===
                                              paymentvalue &&
                                              paymentMethodRes[index]?.code !==
                                              "cashondelivery" && (
                                                <Box>
                                                  {paymentvalue !==
                                                    "telr_telrpayments" && (
                                                      <h1>{paymentvalue}</h1>
                                                    )}
                                                  {!successUrl &&
                                                    successUrl === "" && (
                                                      <Grid item xs={12}>
                                                        <div id="telr_frame"></div>
                                                        {isSubmit &&
                                                        paymentvalue ===
                                                          "telr_telrpayments" &&
                                                        (!telerToken ||
                                                          telerToken === "") ? (
                                                          <div className="error-msg">
                                                            {t(
                                                              "auth.checkout.error.telr"
                                                            )}
                                                          </div>
                                                        ) : null}
                                                      </Grid>
                                                    )}
                                                </Box>
                                              )}
                                          </>
                                        );
                                      }
                                    )}
                                </RadioGroup>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                          
                        {((paymentvalue === "postpay" ||
                          paymentvalue === "cashondelivery") ||
                          (paymentvalue === "tabby_installments" && cartDetails.prices.grand_total.value <= 5000)
                        ) &&
                          !successUrl &&
                          ccevenueRes === "" && (
                            <FormControl fullWidth>
                              {paymentvalue !== undefined ? (
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label" 
                                  name="radio-buttons-group"
                                  sx={{ gap: "15px" }}
                                  className="payment-method-box"
                                  value={paymentvalue}
                                  onChange={handlePaymentChange}
                                >
                                  {paymentMethodRes &&
                                    paymentMethodRes?.map(
                                      (item: any, index: number) => {
                                        return (
                                          <>
                                            <FormControlLabel
                                              key={index}
                                              value={
                                                paymentMethodRes[index]?.code
                                              }
                                              control={
                                                <Radio className="payment-radio" />
                                              }
                                              label={
                                                paymentMethodRes[index]?.title
                                              }
                                              sx={{
                                                backgroundColor: "#F1F3F6",
                                                mx: 0,
                                                border: "0.25px solid #000",
                                                padding: "10px 20px",
                                                color: "#B0B5B6",
                                              }}
                                            />
                                          </>
                                        );
                                      }
                                    )}
                                </RadioGroup>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                        {paymentvalue === "ccavenue" &&
                          !successUrl &&
                          ccevenueRes === "" && (
                            <FormControl fullWidth>
                              {paymentvalue !== undefined ? (
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  sx={{ gap: "15px" }}
                                  className="payment-method-box"
                                  value={paymentvalue}
                                  onChange={handlePaymentChange}
                                >
                                  {paymentMethodRes &&
                                    paymentMethodRes?.map(
                                      (item: any, index: number) => {
                                        return (
                                          <>
                                            <FormControlLabel
                                              key={index}
                                              value={
                                                paymentMethodRes[index]?.code
                                              }
                                              control={
                                                <Radio className="payment-radio" />
                                              }
                                              label={
                                                paymentMethodRes[index]?.title
                                              }
                                              sx={{
                                                backgroundColor: "#F1F3F6",
                                                mx: 0,
                                                border: "0.25px solid #000",
                                                padding: "10px 20px",
                                                color: "#B0B5B6",
                                              }}
                                            />
                                          </>
                                        );
                                      }
                                    )}
                                </RadioGroup>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                        {paymentvalue === "postpay" && (
                          <PostpayWidget
                            amt={
                              cartDetails?.prices?.grand_total?.value
                            }
                            page="checkout" />
                        )}
                        {paymentvalue === "tabby_installments" && cartDetails.prices.grand_total.value <= 7000 && (
                          <TabbyWidget
                            amt={
                              cartDetails?.prices?.grand_total?.value
                            }
                            currency={cartDetails?.prices?.grand_total?.currency}
                            store={store}
                            page="checkout" />
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={4}>
                  <Card
                    sx={{
                      maxWidth: "100%",
                      boxShadow: "none",
                      border: "1px solid #E0DEDE",
                      p: 2,
                    }}
                  >
                    <CardHeader
                      sx={{
                        backgroundColor: "#eee",
                        padding: "12px",
                        "& .MuiTypography-root": {
                          fontSize: "16px",
                          fontWeight: "600",
                        },
                      }}
                      title={t("auth.checkout.order_summary")}
                    />

                    <CardContent sx={{ px: 0 }}>  
                      <Box>
                        <Box sx={{ display: isMobileScreen ? 'block' : 'flex', gap: '10px', mb: 2 }}>
                          <TextField
                            required
                            fullWidth
                            name="apply-coupon"
                            placeholder={couponCodeSuccess ? t("auth.checkout.removed-coupon") : "Enter your Coupon"}
                            type="text"
                            id="apply-coupon"
                            autoComplete="off"
                            className="apply-coupon-box small-textfield"
                            value={couponCode}
                            disabled={couponCodeSuccess}
                            onChange={handleCouponChange}
                            sx={{
                              width: isMobileScreen ? '100%' : '600px',
                              mb: isMobileScreen ? 1 : 0,
                              fontSize: isMobileScreen ? '14px' : '16px',
                              color: couponCodeSuccess ? 'lightgray' : 'inherit',
                            }}
                          />
                          <Button
                            fullWidth
                            variant="contained"
                            sx={{
                              textTransform: 'capitalize',
                              color: '#fff',
                              fontSize: isMobileScreen ? '13px' : '14px',
                              fontWeight: '500',
                              padding: isMobileScreen ? '4px 16px' : '6px 16px',
                            }}
                            onClick={couponCodeSuccess ? handleRemoveCoupon : handleApplyCoupon}
                          >

                            {couponCodeSuccess ? t("auth.checkout.remove-coupon") : t("auth.checkout.apply-coupon")}
                          </Button>

                        </Box>
                        <Box sx={{ minWidth: 100 }}>
                          <TextField
                            required
                            fullWidth
                            name="total-qty"
                            placeholder={`${orderSummaryData?.items?.length} Item in cart`}
                            type="text"
                            id="total-qty"
                            autoComplete="off"
                            className="total-qty-box small-textfield"
                            disabled
                          />
                        </Box>
                        <div className="cart-total-items">
                          {orderSummaryData &&
                            orderSummaryData?.items.map(
                              (data: any, index: any) => (
                                <div
                                  className="cart-content"
                                  style={{ padding: "15px 0px" }}
                                >
                                  <Box sx={{ height: 100, width: 100 }}>
                                    <CardMedia
                                      component="img"
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                      }}
                                      image={data?.product?.small_image?.url}
                                      alt={data?.product?.small_image?._typename}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: 330,
                                    }}
                                  >
                                    <CardContent
                                      sx={{
                                        flex: "1 0 auto",
                                        pt: 0,
                                        pl: 0,
                                      }}
                                    >
                                      <Typography
                                        gutterBottom
                                        component="div"
                                        variant="h5"
                                        sx={{
                                          fontSize: 16,
                                          color: "#252525",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {data?.product?.name}
                                      </Typography>
                                    </CardContent>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        gap: '10px',
                                        pl: 1,
                                        pb: 1,
                                      }}
                                    >
                                      <div className="qty-box">
                                        <label>{t("auth.checkout.Qty")}</label>
                                        <Box>
                                          <FormControl
                                            fullWidth
                                            sx={{
                                              border: "1px solid #C6C3C3",
                                              borderRadius: "2px",
                                            }}
                                          >
                                            <Input
                                              className="quantity-input-filed"
                                              type="number"
                                              value={prodQty[index].qty}
                                              onChange={(e: any) =>
                                                handleQuantityChange(
                                                  prodQty[index].sku,
                                                  prodQty[index].uid,
                                                  e.target.value
                                                )
                                              }
                                              inputProps={{ min: 1 }}
                                              disabled={isQtyDisabled}
                                            />
                                          </FormControl>
                                        </Box>
                                      </div>
                                      <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        sx={{
                                          fontSize: 20,
                                          color: "#252525",
                                          fontWeight: "700",
                                        }}
                                      >
                                        {data?.prices?.price?.currency}
                                        &nbsp;
                                        {data?.prices?.price?.value}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        color: "#424141",
                                        fontSize: "12px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {/* <Link to="/">
                                            {t("auth.checkout.view_details")}
                                          </Link> */}
                                    </Box>
                                  </Box>
                                </div>
                              )
                            )}
                        </div>

                        <Divider />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "15px",
                            py: 3,
                          }}
                        >
                          <div className="final-cart-details">
                            <label className="item-lbl">
                              {t("auth.checkout.cart_subtotal")}
                            </label>
                            <span className="item-val">
                              {cartSubTotalCurr}&nbsp;
                              {cartSubTotal}
                            </span>
                          </div>
                          {selectedStore === "sa_en" ||
                            selectedStore === "sa_ar" ||
                            selectedStore === "en" ||
                            selectedStore === "ar" ? (
                            <>
                              <div className="final-cart-details">
                                <label className="item-lbl">
                                  {selectedStore === "sa_en" ||
                                    selectedStore === "sa_ar"
                                    ? t("auth.checkout.ksa_vat")
                                    : selectedStore === "en" ||
                                      selectedStore === "ar"
                                      ? t("auth.checkout.oman_vat")
                                      : ""}
                                </label>
                                <span className="item-val">
                                  {vat?.currency}&nbsp;
                                  {vat?.value}
                                </span>
                              </div>
                              <div className="final-cart-details">
                                <label className="item-lbl">
                                  {t("auth.checkout.custom_duty")}
                                </label>
                                <span className="item-val">
                                  {customDuty?.currency}&nbsp;
                                  {customDuty?.value}
                                </span>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          <div className="final-cart-details">
                            <label className="item-lbl">
                              {t("auth.checkout.shipping")}
                            </label>
                            <span className="item-val">
                              {shipAmtCurr}&nbsp;
                              {shipAmt}
                            </span>
                          </div>
                          {paymentvalue === "cashondelivery" && (
                            <div className="final-cart-details">
                              <label className="item-lbl">
                                {t("auth.checkout.COD")}
                              </label>
                              <span className="item-val">AED 10</span>
                            </div>
                          )}
                          {couponCodeSuccess && discountPrice &&
                            discountPrice.map((data: any) => (
                              <Box className="disc-cart-details" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <label className="item-lbl">
                                  {t("auth.checkout.discount")}({data?.label})
                                </label>
                                <span className="item-val">-{data?.amount?.currency} {data?.amount?.value}</span>
                              </Box>
                            ))}

                        </Box>
                        <Divider />
                        <div className="final-cart-details">
                          <label className="item-lbl">
                            {t("auth.checkout.total")}
                          </label>
                          <span className="item-val">
                            {orderSummaryData?.prices?.grand_total?.currency}
                            &nbsp;
                            {orderSummaryData?.prices?.grand_total?.value}
                          </span>
                        </div>
                        <div className="is-mobile-fixed">
                          <Button
                            type="submit"
                            // id="submit_form"
                            onClick={(e) => SubmitForm(e)}
                            fullWidth
                            disabled={procedToPayToggle}
                            variant="contained"
                            sx={{
                              textTransform: "capitalize",
                              mt: isMobileScreen ? 0 : 2,
                              color: "#fff",
                              fontSize: "18px",
                              fontWeight: "500",
                              padding: "6px 34px",
                            }}
                          >
                            {placeOrderLoading ? ( // If loading, show CircularProgress
                              <CircularProgress size={24} />
                            ) : (
                              // Otherwise, show button text
                              paymentvalue === "cashondelivery" ? "Place Order" : "Proceed to Pay"
                            )}
                            {/* {paymentvalue === "cashondelivery"
                              ? "Place Order"
                              : "Proceed to Pay"} */}
                          </Button>
                        </div>
                      </Box>
                      <AddNewAddress
                        isOpen={open}
                        handleCloseModal={handleCloseModal}
                        isEdit={isEdit}
                        setSuccessAddress={(event:any) => setSuccessAddress(event)}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            {ccevenueRes && <CCAvenueForm data={ccevenueRes} />}
          </Container>
        </div>
        {isOpenRegister && (
          <Modal
            selectedValue="register"
            open={isOpenRegister}
            onClose={(value: any) => setIsOpenRegister(value)}
          />
        )}
        {isOpenFPwd && (
          <Modal
            selectedValue="forgotpwd"
            open={isOpenFPwd}
            onClose={(value: any) => setIsOpenFPwd(value)}
          />
        )}
      </div>
      {paymentFailed && (
        <PaymentFailer
          isOpen={true}
          onClose={() => { setPaymentFailed(false) }}
          title={t("auth.checkout.paymentFailTitle")}
          description1={t("auth.checkout.paymentFailDesc1")}
          description2={t("auth.checkout.paymentFailDesc2")}
        />
      )}
    </>
  );
}
export default Checkout;
