import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import './i18n';

const client = new ApolloClient({
  // uri: "https://access.jebelz.com/graphql",
  // uri: "https://jebelz.com/graphql",
  uri: "https://jebelzm2.ezmartech.com/graphql",
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  <ApolloProvider client={client}>
    <App />
</ApolloProvider>,
);

reportWebVitals();