import React, { useContext, useEffect } from "react";
import { CountryContext } from "./MainComponent";
import Loading from "./Layout/loader"

const SubdomainRedirect = () => {
  const { store } = useContext(CountryContext);
  const host = window.location.hostname
  useEffect(() => {
    if (host.includes("react.") || host.includes("react-")) {
      if(host.includes("react-in.jebelz.com")) {
        if(store === "in") window.location.href = `http://react-in.jebelz.com/${store}`; 
        else window.location.href = `http://react-in.jebelz.com/in`; 
      }
      else if(host.includes("react-sa.jebelz.com")) {
        if(store === "sa_en") window.location.href = `http://react-sa.jebelz.com/${store}`; 
        else if(store === "sa_ar") window.location.href = `http://react-sa.jebelz.com/${store}`; 
        else window.location.href = `http://react-sa.jebelz.com/sa_ar`;
      } else if (host.includes("react-en.jebelz.com")) {
        if(store === "en") window.location.href = `http://react-om.jebelz.com/${store}`; 
        else if(store === "ar") window.location.href = `http://react-om.jebelz.com/${store}`; 
        else window.location.href = `http://react-om.jebelz.com/ar`;
      } else if (host.includes("react.jebelz.com")) {
        if(store === "ae_en") window.location.href = `http://react.jebelz.com/${store}`; 
        else if(store === "ae_ar") window.location.href = `http://react.jebelz.com/${store}`; 
        else window.location.href = `http://react.jebelz.com/ae_en`;
      } else {
        window.location.href = `https://react.jebelz.com/${store}`;
      }
    } else {
      if (store === "in" && !host.includes("in")) {
        window.location.href = ` https://in.jebelz.com/${store}`;
        // window.location.href = `/${store}`
      } else if (store === "sa_ar" && !host.includes("sa")) {
        window.location.href = `https://sa.jebelz.com/${store}`;
      } else if (store === "ar" && !host.includes("om")) {
        window.location.href = `https://om.jebelz.com/${store}`;
      } else {
        window.location.href = `https://jebelz.com/${store}`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  return <><Loading /></>;
};

export default SubdomainRedirect;
